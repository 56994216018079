import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import parse from "html-react-parser";

export default function PrivacyPolicy({isOpenPp, setIsOpenPp}) {
    const [content,setContent] = useState(null);
    const customStyles = {
        content: {
          width: '90%',
          height: '70%',
        },
    };

    function toggleModal() {
        setIsOpenPp(!isOpenPp);
    }

    useEffect(() => {
        fetch('https://staging.api.reallygreatrate.com/landingpagecontent/privacy-policy', {
            method: 'POST',
            body: JSON.stringify({ sitename:"FedRateOptions.com" }),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          })
             .then((response) => response.json())
             .then((resp) => {
                setContent(resp.data.html);
             })
             .catch((err) => {
                console.log(err.message);
             });
    }, []);

    return (
        <>
            <a className={"pointer-cursor text-black-50"} onClick={toggleModal}>Privacy Policy</a>
            <Modal
                isOpen={isOpenPp}
                onRequestClose={toggleModal}
                contentLabel="Privacy policy"
                className="contentModal"
                overlayClassName="contentModalOverlay"
                ariaHideApp={false}
                style={customStyles}
            >
                <div className="contentModalHeader">
                    <div className="row mt-3">
                        <div className="col">
                            <h3>PRIVACY POLICY</h3>
                        </div>
                        <div className="col-auto text-right">
                            <a className="pointer-cursor text-white" onClick={toggleModal}>Close</a>
                        </div>
                    </div>
                </div>
                <div className="contentModalText">
                    {content &&
                        parse(content)
                    }
                </div>
            </Modal>
        </>
    );
}
